import React, { useState } from 'react';
import { Typography, Row, Col, Form, Input, Select, Button, Divider, FormInstance } from 'antd';
import InputMask from 'react-input-mask';

const { Title } = Typography;
const { Option } = Select;

interface PersonalInformationProps {
  form: FormInstance;
}

const PersonalInformation: React.FC<PersonalInformationProps> = ({ form }) => {
  // Track each Trusted Contact using an array of numeric IDs
  const [contacts, setContacts] = useState<any[]>([]);

  // When user clicks "Add Contact," push a new ID
  const handleAddContact = () => {
    setContacts((prevContacts) => [...prevContacts, Date.now()]);
  };

  // Remove a specific contact (by index)
  const handleRemoveContact = (index: number) => {
    const currentContacts = form.getFieldValue('contacts') || [];
    const updatedContacts = currentContacts.filter((_: any, idx: number) => idx !== index);
    form.setFieldsValue({ contacts: updatedContacts });
    setContacts((prevContacts) => prevContacts.filter((_, idx) => idx !== index));
  };

  return (
    <>
      <Title level={4} style={{ marginTop: 0 }}>
        Personal Information
      </Title>
      <p>
        Please provide the personal information of the client whose bills
        will be paid. If there is another individual associated with the
        bills, in the same household, or serving as the best contact for
        the account, you can add their details using the Add Contact button.
      </p>


      {/* ---------- Personal Info Section ---------- */}
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            label="First Name"
            name="firstName"
            rules={[{ required: true, message: 'Please enter first name' }]}
          >
            <Input placeholder="First Name" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Last Name"
            name="lastName"
            rules={[{ required: true, message: 'Please enter last name' }]}
          >
            <Input placeholder="Last Name" />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            label="Email Address"
            name="email"
            rules={[
              { required: true, message: 'Please enter email' },
              { type: 'email', message: 'Please enter a valid email' },
            ]}
          >
            <Input placeholder="Email Address" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Phone Number"
            name="phoneNumber"
            rules={[{ required: true, message: 'Please enter phone number' }]}
          >
            <InputMask mask="999-999-9999" placeholder="Phone Number">
              {(inputProps: any) => <Input {...inputProps} />}
            </InputMask>
          </Form.Item>
        </Col>
      </Row>

      <Form.Item
        label="Address Line 1"
        name="addressLine1"
        rules={[{ required: true, message: 'Please enter address line 1' }]}
      >
        <Input placeholder="Address Line 1" />
      </Form.Item>

      <Form.Item label="Address Line 2" name="addressLine2">
        <Input placeholder="Address Line 2" />
      </Form.Item>

      <Row gutter={16}>
        <Col span={8}>
          <Form.Item
            label="City"
            name="city"
            rules={[{ required: true, message: 'Please enter city' }]}
          >
            <Input placeholder="City" />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label="State"
            name="state"
            rules={[{ required: true, message: 'Please select a state' }]}
          >
            <Select placeholder="State">
              <Option value="AL">AL</Option>
              <Option value="AK">AK</Option>
              <Option value="AZ">AZ</Option>
              <Option value="AR">AR</Option>
              <Option value="CA">CA</Option>
              <Option value="CO">CO</Option>
              <Option value="CT">CT</Option>
              <Option value="DE">DE</Option>
              <Option value="FL">FL</Option>
              <Option value="GA">GA</Option>
              <Option value="HI">HI</Option>
              <Option value="ID">ID</Option>
              <Option value="IL">IL</Option>
              <Option value="IN">IN</Option>
              <Option value="IA">IA</Option>
              <Option value="KS">KS</Option>
              <Option value="KY">KY</Option>
              <Option value="LA">LA</Option>
              <Option value="ME">ME</Option>
              <Option value="MD">MD</Option>
              <Option value="MA">MA</Option>
              <Option value="MI">MI</Option>
              <Option value="MN">MN</Option>
              <Option value="MS">MS</Option>
              <Option value="MO">MO</Option>
              <Option value="MT">MT</Option>
              <Option value="NE">NE</Option>
              <Option value="NV">NV</Option>
              <Option value="NH">NH</Option>
              <Option value="NJ">NJ</Option>
              <Option value="NM">NM</Option>
              <Option value="NY">NY</Option>
              <Option value="NC">NC</Option>
              <Option value="ND">ND</Option>
              <Option value="OH">OH</Option>
              <Option value="OK">OK</Option>
              <Option value="OR">OR</Option>
              <Option value="PA">PA</Option>
              <Option value="RI">RI</Option>
              <Option value="SC">SC</Option>
              <Option value="SD">SD</Option>
              <Option value="TN">TN</Option>
              <Option value="TX">TX</Option>
              <Option value="UT">UT</Option>
              <Option value="VT">VT</Option>
              <Option value="VA">VA</Option>
              <Option value="WA">WA</Option>
              <Option value="WV">WV</Option>
              <Option value="WI">WI</Option>
              <Option value="WY">WY</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label="Zip Code"
            name="zipCode"
            rules={[{ required: true, message: 'Please enter zip code' }]}
          >
            <InputMask mask="99999" placeholder="Zip Code">
              {(inputProps: any) => <Input {...inputProps} />}
            </InputMask>
          </Form.Item>
        </Col>
      </Row>

      {/* ---------- Conditionally render the Divider if there is at least one trusted contact ---------- */}
      {contacts.length > 0 && (
        <Divider style={{ borderTop: '1px solid grey' }} />
      )}

      {/* ---------- Render a "Contact Information" block for each trusted contact ---------- */}
      {contacts.map((id, index) => (
        <div key={id} style={{ marginBottom: '2rem', border: '1px solid #ccc', padding: '1rem' }}>
          <Title level={4} style={{ marginTop: 0 }}>Contact Information #{index + 1}</Title>

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="First Name"
                name={['contacts', index, 'firstName']}
                rules={[{ required: true, message: 'Please enter first name' }]}
              >
                <Input placeholder="First Name" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Last Name"
                name={['contacts', index, 'lastName']}
                rules={[{ required: true, message: 'Please enter last name' }]}
              >
                <Input placeholder="Last Name" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="Email Address"
                name={['contacts', index, 'email']}
                rules={[
                  { required: true, message: 'Please enter email' },
                  { type: 'email', message: 'Please enter a valid email' },
                ]}
              >
                <Input placeholder="Email Address" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Phone Number"
                name={['contacts', index, 'phoneNumber']}
                rules={[{ required: true, message: 'Please enter phone number' }]}
              >
                <InputMask mask="999-999-9999" placeholder="Phone Number">
                  {(inputProps: any) => <Input {...inputProps} />}
                </InputMask>
              </Form.Item>
            </Col>
          </Row>

          <Form.Item
            label="Address Line 1"
            name={['contacts', index, 'addressLine1']}
          >
            <Input placeholder="Address Line 1" />
          </Form.Item>

          <Form.Item
            label="Address Line 2"
            name={['contacts', index, 'addressLine2']}
          >
            <Input placeholder="Address Line 2" />
          </Form.Item>

          <Row gutter={16}>
            <Col span={8}>
              <Form.Item
                label="City"
                name={['contacts', index, 'city']}
              >
                <Input placeholder="City" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="State"
                name={['contacts', index, 'state']}
              >
                <Select placeholder="State">
                  <Option value="AL">AL</Option>
                  <Option value="AK">AK</Option>
                  <Option value="AZ">AZ</Option>
                  <Option value="AR">AR</Option>
                  <Option value="CA">CA</Option>
                  <Option value="CO">CO</Option>
                  <Option value="CT">CT</Option>
                  <Option value="DE">DE</Option>
                  <Option value="FL">FL</Option>
                  <Option value="GA">GA</Option>
                  <Option value="HI">HI</Option>
                  <Option value="ID">ID</Option>
                  <Option value="IL">IL</Option>
                  <Option value="IN">IN</Option>
                  <Option value="IA">IA</Option>
                  <Option value="KS">KS</Option>
                  <Option value="KY">KY</Option>
                  <Option value="LA">LA</Option>
                  <Option value="ME">ME</Option>
                  <Option value="MD">MD</Option>
                  <Option value="MA">MA</Option>
                  <Option value="MI">MI</Option>
                  <Option value="MN">MN</Option>
                  <Option value="MS">MS</Option>
                  <Option value="MO">MO</Option>
                  <Option value="MT">MT</Option>
                  <Option value="NE">NE</Option>
                  <Option value="NV">NV</Option>
                  <Option value="NH">NH</Option>
                  <Option value="NJ">NJ</Option>
                  <Option value="NM">NM</Option>
                  <Option value="NY">NY</Option>
                  <Option value="NC">NC</Option>
                  <Option value="ND">ND</Option>
                  <Option value="OH">OH</Option>
                  <Option value="OK">OK</Option>
                  <Option value="OR">OR</Option>
                  <Option value="PA">PA</Option>
                  <Option value="RI">RI</Option>
                  <Option value="SC">SC</Option>
                  <Option value="SD">SD</Option>
                  <Option value="TN">TN</Option>
                  <Option value="TX">TX</Option>
                  <Option value="UT">UT</Option>
                  <Option value="VT">VT</Option>
                  <Option value="VA">VA</Option>
                  <Option value="WA">WA</Option>
                  <Option value="WV">WV</Option>
                  <Option value="WI">WI</Option>
                  <Option value="WY">WY</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Zip Code"
                name={['contacts', index, 'zip']}
              >
                <InputMask mask="99999" placeholder="Zip Code">
                  {(inputProps: any) => <Input {...inputProps} />}
                </InputMask>
              </Form.Item>
            </Col>
          </Row>

          <Form.Item
            label="Contact Type"
            name={['contacts', index, 'type']}
            rules={[{ required: true, message: 'Please select contact type' }]}
          >
            <Select placeholder="Contact Type">
              <Option value="ACCOUNTANT_TAX_PREPARER">
                Accountant/Tax Preparer
              </Option>
              <Option value="ATTORNEY">Attorney</Option>
              <Option value="CASEWORKER">Caseworker</Option>
              <Option value="FAMILY">Family</Option>
              <Option value="FRIEND">Friend</Option>
              <Option value="GUARDIAN">Guardian</Option>
              <Option value="HOME_HEALTH_AID">Home Health Aid</Option>
              <Option value="NEIGHBOR">Neighbor</Option>
              <Option value="WEALTH_MANAGER">Wealth Manager</Option>
            </Select>
          </Form.Item>

          {/* ---------- Remove Contact Button ---------- */}
          <Button
            type="link"
            danger
            onClick={() => handleRemoveContact(index)}
          >
            Remove
          </Button>
        </div>
      ))}
      {/* ---------- Button to Add Contact ---------- */}
      <Button
        type="default"
        style={{ marginBottom: '2rem' }}
        onClick={handleAddContact}
      >
        Add Contact
      </Button>
    </>
  );
};

export default PersonalInformation;
