// FinancialInformation.tsx

import React from 'react';
import { Typography, Form, Input, Button, Row, Col, Select, Checkbox } from 'antd';
import InputMask from 'react-input-mask';

const { Title } = Typography;
const { Option } = Select;

interface FinancialInformationProps {
  onPrimaryChange: (checked: boolean, index: number) => void;
}

const FinancialInformation: React.FC<FinancialInformationProps> = ({
  onPrimaryChange,
}) => {
  return (
    <>
      <Title level={4} style={{ marginTop: 0 }}>Financial Information</Title>
      <p>
        All data is securely stored in our encrypted database to protect your
        privacy. You must add at least one bank account to use for your monthly
        subscription fee and bill payments. If you use multiple bank accounts,
        please add them here.
      </p>

      <Form.List name="bankAccounts">
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, ...restField }, index) => (
              <div
                key={key}
                style={{
                  marginBottom: '1.5rem',
                  padding: '1rem',
                  border: '1px solid #f0f0f0',
                  borderRadius: '4px',
                }}
              >
                <Row gutter={16}>
                  <Col span={24}>
                    <Form.Item
                      {...restField}
                      label="Name on Bank Account"
                      name={[name, 'accountName']}
                      rules={[
                        {
                          required: true,
                          message: 'Please enter the name on the bank account',
                        },
                      ]}
                    >
                      <Input placeholder="Full Name on Account" />
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item
                      {...restField}
                      label="Bank Name"
                      name={[name, 'bankName']}
                      rules={[
                        {
                          required: true,
                          message: 'Please enter bank name',
                        },
                      ]}
                    >
                      <Input placeholder="Bank Name" />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      {...restField}
                      label="Account Type"
                      name={[name, 'accountType']}
                      rules={[
                        {
                          required: true,
                          message: 'Please select account type',
                        },
                      ]}
                    >
                      <Select>
                        <Option value="checking">Checking</Option>
                        <Option value="savings">Savings</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item
                      {...restField}
                      label="Routing Number"
                      name={[name, 'routingNumber']}
                      rules={[
                        {
                          required: true,
                          message: 'Please enter routing number',
                        },
                      ]}
                    >
                      <InputMask mask="999999999" placeholder="Routing Number">
                        {(inputProps: any) => <Input {...inputProps} />}
                      </InputMask>
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      {...restField}
                      label="Account Number"
                      name={[name, 'accountNumber']}
                      rules={[
                        {
                          required: true,
                          message: 'Please enter account number',
                        },
                      ]}
                    >
                      <Input placeholder="Account Number" />
                    </Form.Item>
                  </Col>
                </Row>

                <Form.Item
                  {...restField}
                  name={[name, 'primary']}
                  valuePropName="checked"
                  style={{ marginBottom: '0.5rem' }}
                >
                  <Checkbox
                    onChange={(e) => onPrimaryChange(e.target.checked, index)}
                    onClick={(e) => {
                      const checkbox = e.target as HTMLInputElement;
                      if (checkbox.checked) {
                        e.preventDefault();
                      }
                    }}
                  >
                    Primary
                  </Checkbox>
                </Form.Item>

                {fields.length > 1 && (
                  <Button
                    type="dashed"
                    danger
                    onClick={() => {
                      remove(name);
                      // If after removal there will be only one account left, set it as primary
                      if (fields.length === 2) {
                        const remainingIndex = name === 0 ? 0 : 0;
                        onPrimaryChange(true, remainingIndex);
                      }
                    }}
                    style={{ marginTop: '0.5rem' }}
                  >
                    Remove This Bank Account
                  </Button>
                )}
              </div>
            ))}

            <Button
              type="default"
              style={{ marginBottom: '2rem' }}
              onClick={() =>
                add({
                  accountName: '',
                  bankName: '',
                  accountType: 'checking',
                  routingNumber: '',
                  accountNumber: '',
                  primary: fields.length === 0, // Set primary to true if this is the first account
                })
              }
            >
              Add Bank Account
            </Button>
          </>
        )}
      </Form.List>
    </>
  );
};

export default FinancialInformation;
