

import React from 'react';
import { Tabs } from 'antd';
import type { TabsProps } from 'antd';
import Uploads from './Uploads';
import UnprocessedList from './UnprocessedList';

const items: TabsProps['items'] = [
  {
    key: '1',
    label: 'Upload',
    children: <Uploads />
  },
  {
    key: '2',
    label: 'List',
    children: <UnprocessedList />,
  },
];

const DocumentsUpload: React.FC = () => {
  return (
    <>
      <h1>Documents Upload</h1>
      <Tabs defaultActiveKey="1" items={items} />
    </>
  );
};

export default DocumentsUpload;