// SectionIndicator.tsx

import React from 'react';
import { Steps } from 'antd';

const { Step } = Steps;

interface SectionIndicatorProps {
    currentStep: number;
    steps: string[]; // Just an array of titles
}

const SectionIndicator: React.FC<SectionIndicatorProps> = ({
    currentStep,
    steps,
}) => {
    return (
        <Steps current={currentStep} style={{ marginBottom: '2rem' }}>
            {steps.map((title, index) => (
                <Step key={index} title={title} />
            ))}
        </Steps>
    );
};

export default SectionIndicator;
