// Signup.tsx

import { useState } from 'react';
import {
  Form,
  Button,
  Row,
  Col,
  Typography,
} from 'antd';
import { useMutation, gql } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import SectionIndicator from '../../components/SectionIndicator';

// Child components
import PersonalInformation from './PersonalInformation';
import FinancialInformation from './FinancialInformation';

const { Title } = Typography;

// --------------------------------------------
// 1. Define Your GraphQL Mutation
// --------------------------------------------
const REGISTER_MUTATION = gql`
  mutation Register(
    $firstName: String!
    $lastName: String!
    $email: String!
    $addressLine1: String!
    $addressLine2: String
    $state: String!
    $city: String!
    $zip: String!
    $phoneNumber: String!
    $externalBankAccounts: [ExternalBankAccountInput!]!
    $contacts: [ContactInput!]
  ) {
    register(
      firstName: $firstName
      lastName: $lastName
      email: $email
      addressLine1: $addressLine1
      addressLine2: $addressLine2
      state: $state
      city: $city
      zip: $zip
      phoneNumber: $phoneNumber
      externalBankAccounts: $externalBankAccounts,
      contacts: $contacts
    )
  }
`;

// --------------------------------------------
// 2. The Signup Component
// --------------------------------------------
const Signup = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState(0);

  const [register, { loading: mutationLoading, error: mutationError }] =
    useMutation(REGISTER_MUTATION);

  // Step Titles for the Reusable Indicator
  const stepTitles = [
    'Personal Information',
    'Financial Information',
    'Terms Acknowledgement',
  ];

  // --------------------------------------------
  // Submission Handler
  // --------------------------------------------
  const onFinish = async () => {
    const data = form.getFieldsValue(true);
    const {
      firstName,
      lastName,
      email,
      phoneNumber,
      addressLine1,
      addressLine2,
      city,
      state,
      zipCode,
      bankAccounts,
      contacts
    } = data;

    // Transform bankAccounts into shape required by the API
    const externalBankAccounts = bankAccounts.map((account: any) => ({
      nameOnBankAccount: account.accountName,
      bankName: account.bankName,
      type: account.accountType.toUpperCase(),
      routingNumber: account.routingNumber,
      accountNumber: account.accountNumber,
      isPrimary: account.primary,
    }));

    try {
      const { data } = await register({
        variables: {
          firstName,
          lastName,
          email,
          phoneNumber,
          addressLine1,
          addressLine2,
          city,
          state,
          zip: zipCode,
          externalBankAccounts,
          contacts
        },
      });
      console.log('Success:', data);

      // data.register should contain the GUID
      const guid = data.register;
      // Navigate to the terms page with GUID
      navigate(`/approvals/terms?token=${guid}&signup=true`);
    } catch (err) {
      console.error('GraphQL Error:', err);
    }
  };

  // --------------------------------------------
  // "Primary" Checkbox Handler
  // --------------------------------------------
  const onPrimaryChange = (checked: boolean, index: number) => {
    if (checked) {
      const values = form.getFieldsValue();
      const updatedBankAccounts = values.bankAccounts.map(
        (account: any, idx: number) => ({
          ...account,
          primary: idx === index,
        })
      );
      form.setFieldsValue({ bankAccounts: updatedBankAccounts });
    }
  };

  // --------------------------------------------
  // Step Navigation Handlers
  // --------------------------------------------
  const onNext = async () => {
    try {
      if (currentStep === 0) {
        // Validate Personal Info fields only
        await form.validateFields([
          'firstName',
          'lastName',
          'email',
          'phoneNumber',
          'addressLine1',
          'city',
          'state',
          'zipCode',
        ]);
      }
      setCurrentStep(currentStep + 1);
    } catch (error) {
      console.log('Validation error:', error);
    }
  };

  const onPrev = () => {
    setCurrentStep(currentStep - 1);
  };

  // --------------------------------------------
  // Choose which step content to render
  // --------------------------------------------
  const renderStepContent = () => {
    switch (currentStep) {
      case 0:
        return <PersonalInformation form={form} />;
      case 1:
        return <FinancialInformation onPrimaryChange={onPrimaryChange} />;
      default:
        return <p>Some future step or fallback content here.</p>;
    }
  };

  return (
    <Row justify="center">
      <Col xs={24} sm={20} md={16} lg={12}>
        <Title level={3} style={{ textAlign: 'center', marginBottom: '2rem' }}>
          Sign Up
        </Title>

        {/* Reusable Section Indicator */}
        <SectionIndicator currentStep={currentStep} steps={stepTitles} />

        <Form
          form={form}
          name="signUpForm"
          layout="vertical"
          preserve={true}
          initialValues={{
            bankAccounts: [
              {
                accountName: '',
                bankName: '',
                accountType: 'checking',
                routingNumber: '',
                accountNumber: '',
                primary: true,
              },
            ],
          }}
          onFinish={onFinish}
          style={{ background: '#fff', padding: '2rem', borderRadius: '4px', marginBottom: '2rem' }}
        >
          {/* Step-specific content */}
          {renderStepContent()}

          <Form.Item style={{ marginBottom: 0 }}>
            <Row justify="space-between">
              <Col>
                {currentStep > 0 && (
                  <Button onClick={onPrev}>Previous</Button>
                )}
              </Col>

              <Col>
                {currentStep === 0 && (
                  <Button type="primary" onClick={onNext}>
                    Next
                  </Button>
                )}
                {currentStep === 1 && (
                  <Button
                    type="primary"
                    htmlType="submit"
                    loading={mutationLoading}
                  >
                    Save & Continue
                  </Button>
                )}
              </Col>
            </Row>
          </Form.Item>


          {/* Show any GraphQL error */}
          {mutationError && (
            <p style={{ color: 'red' }}>Error: {mutationError.message}</p>
          )}
        </Form>
      </Col>
    </Row>
  );
};

export default Signup;
